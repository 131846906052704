<template>
  <v-dialog
    :value="dlg.visible"
    width="900"
    persistent
  >
    <v-card
      class="white"
      flat
    >
      <v-card-title>
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>
            {{ btnClose.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card
        class="overflow-y-auto overflow-x-hidden blue-grey lighten-5 pa-6"
        flat
        max-height="700"
      >
        <v-row dense>
          <v-col
            v-for="(item, i) in items"
            :key="`wk_${i}`"
            cols="12"
            md="4"
          >
            <v-hover
              v-slot="{ hover }"
              :disabled="!hovered"
              close-delay="120"
            >
              <v-card
                :elevation="hover ? hoverElevation : normalElevation"
                :class="['rounded', 'transition-swing']"
                color="white"
                flat
                tile
              >
                <v-img
                  :aspect-ratio="ratio"
                  :src="item.imageUrl"
                  height="inherit"
                >
                  <div class="d-flex justify-end mt-3 mr-3">
                    <div
                      class="pa-1 rounded"
                      :class="[item.selected ? 'selected-icon-bg' : 'unselect-icon-bg']"
                      @click="onCheck(item)"
                    >
                      <v-icon
                        size="32"
                        :color="item.selected ? icons.selectedColor : icons.unselectColor"
                      >
                        {{ item.selected ? icons.selectedIcon : icons.unselectIcon }}
                      </v-icon>
                    </div>
                  </div>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card>
      <v-divider />
      <v-card-actions>
        <v-pagination
          v-model="pagination.current"
          :length="pagination.length"
          :total-visible="pagination.totalVisible"
          @input="changePagination($event)"
        />
        <v-spacer />
        <span class="co-text-sm co-text-gray-500 mr-4">
          <span class="co-text-gray-800">
            {{ selects.length }}
          </span>
          <span class="px-1">/</span>
          {{ limit }}
        </span>
        <v-btn
          color="blue-grey darken-3"
          text
          @click="onClose"
        >
          {{ btns.cancelText }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onConfirm"
        >
          {{ btns.confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import api from '@/api/co.api'
  // import app from '@/api/co.app'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  export default {
    props: {
    },
    data () {
      return {
        btnClose: {
          icon: 'mdi-close'
        },
        dlg: {
          title: '我的作品',
          visible: false
        },
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
        selects: [],
        limit: 9,
        ratio: 1.45,
        normalElevation: 0,
        hoverElevation: 18,
        hovered: true,
        params: null,
        items: [],
        userInfo: {},
        btns: {
          cancelText: '关闭',
          confirmText: '确定'
        },
        icons: {
          unselectIcon: 'mdi-checkbox-blank-outline',
          unselectColor: 'white',
          selectedIcon: 'mdi-checkbox-marked',
          selectedColor: 'green'
        }
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.getContents()
    },
    methods: {
      getIndexFromSelects (item) {
        let result = -1
        const cnt = this.selects.length
        for (let i = 0; i < cnt; i++) {
          const src = this.selects[i]
          if (src.key === item.key) {
            result = i
            break
          }
        }

        return result
      },
      addToSelects (item) {
        const dst = Object.assign({}, item)
        const ind = this.getIndexFromSelects(item)
        if (ind < 0) {
          this.selects.push(dst)
        } else {
          this.selects[ind] = dst
        }
      },
      removeFromSelected (item) {
        const ind = this.getIndexFromSelects(item)
        if (ind >= 0) {
          this.selects.splice(ind, 1)
        }
      },
      onCheck (item) {
        const cnt = this.selects.length
        if (!item.selected) {
          if (cnt >= this.limit) {
            return
          }
        }

        item.selected = !item.selected
        if (item.selected) {
          this.addToSelects(item)
        } else {
          this.removeFromSelected(item)
        }
      },
      reset () {
        for (const i in this.items) {
          const dst = this.items[i]
          dst.selected = false
        }
      },
      show () {
        this.selects = []
        this.dlg.visible = true
      },
      onClose (ev) {
        this.reset()
        this.dlg.visible = false
        this.$emit('cancel', ev)
      },
      onConfirm () {
        // console.log('this.selects: %o', this.selects)
        this.dlg.visible = false
        this.$emit('confirm', this.selects)
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configItems () {
        /**
         * key 的规则 img-{pageIndx}-{itemIndex}-{thumbIndex}
        */
        this.items = []
        const pre = `img-${this.pagination.current}`
        for (const i in this.params.items) {
          const src = this.params.items[i] || {}
          const thumbs = src.thumbs || []
          for (const j in thumbs) {
            const imageUrl = thumbs[j]
            const dst = {
              key: `${pre}-${i}-${j}`,
              contentId: src.contentId,
              title: src.title,
              imageUrl,
              type: src.type,
              selected: false
            }

            const ind = this.getIndexFromSelects(dst)
            dst.selected = ind >= 0
            this.items.push(dst)
          }
        }
      },
      getContents () {
        const me = this
        const executed = function (res) {
          if (res.status) {
            const data = me.params.data
            const pagination = me.pagination
            const pageSize = parseInt(data.pageSize) || 9
            pagination.length = Math.ceil(res.total / pageSize)
            me.configItems()
          }
        }

        me.params = web.content.getParamsPersonal({
          type: web.comm.ContentTypes.IMAGE,
          state: web.comm.States.PUBLISH,
          executed
        })

        api.httpx.getItems(me.params)
      },
    }
  }
</script>

<style lang="scss">
.selected-icon-bg {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.unselect-icon-bg {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

</style>
